//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import featureFlagMixin from '../mixins/feature-flag.mixin';

export default {
  name: 'AuthModalsWrapper',
  mixins: [featureFlagMixin],
  components: {
    AuthModal: () => import('./AuthModal.vue'),
    LoginForm: () => import('./forms/LoginForm.vue'),
    SignupForm: () => import('./forms/SignupForm.vue'),
    LostPasswordForm: () => import('./forms/LostPasswordForm.vue'),
  },
};
